/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ZendeskWebhookRequest = {
    action: ZendeskWebhookRequest.action;
    ticketId: number;
    externalId?: string;
    userId?: string;
    phoneNumber?: string;
    assigneeEmail?: string;
};

export namespace ZendeskWebhookRequest {

    export enum action {
        COMMENT = 'COMMENT',
        TICKET_CLOSED = 'TICKET_CLOSED',
        SMS_USER_TO_9AM = 'SMS_USER_TO_9AM',
        SMS_9AM_TO_USER = 'SMS_9AM_TO_USER',
        PHONE_CALL_USER_TO_9AM = 'PHONE_CALL_USER_TO_9AM',
        PHONE_CALL_9AM_TO_USER = 'PHONE_CALL_9AM_TO_USER',
        SHIPMENT_COMMENT = 'SHIPMENT_COMMENT',
        SHIPMENT_COMPLETED = 'SHIPMENT_COMPLETED',
    }


}

