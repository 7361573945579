/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A list of reasons in case the user is ineligible.
 */
export type IneligibilityReason = {
    reason?: IneligibilityReason.reason;
    source?: IneligibilityReason.source;
    code?: string;
    description?: string;
};

export namespace IneligibilityReason {

    export enum reason {
        UNKNOWN = 'UNKNOWN',
        DATA_PROCESSING_ERROR = 'DATA_PROCESSING_ERROR',
        CONDITION_NOT_MET = 'CONDITION_NOT_MET',
    }

    export enum source {
        NO_LAB_REPORT = 'NO_LAB_REPORT',
        BIRTH_DATE = 'BIRTH_DATE',
        STATE = 'STATE',
        INSULIN_AND_GLIPIZIDE = 'INSULIN_AND_GLIPIZIDE',
        INSULIN_AND_GLIMEPIRIDE = 'INSULIN_AND_GLIMEPIRIDE',
        INSULIN_PUMP = 'INSULIN_PUMP',
        UNLISTED_DIABETES_MEDICATIONS = 'UNLISTED_DIABETES_MEDICATIONS',
        UNLISTED_BLOOD_PRESSURE_MEDICATIONS = 'UNLISTED_BLOOD_PRESSURE_MEDICATIONS',
        UNLISTED_CHOLESTEROL_MEDICATIONS = 'UNLISTED_CHOLESTEROL_MEDICATIONS',
        RECENT_HBA1C_RESULT = 'RECENT_HBA1C_RESULT',
        UNSUPPORTED_DIABETES_TYPE = 'UNSUPPORTED_DIABETES_TYPE',
        PREGNANT = 'PREGNANT',
        BREASTFEEDING = 'BREASTFEEDING',
        EXISTING_PRECONDITIONS = 'EXISTING_PRECONDITIONS',
        NO_EXISTING_PRECONDITIONS = 'NO_EXISTING_PRECONDITIONS',
        SYMPTOMS_OF_DIABETIC_KETOACIDOSIS = 'SYMPTOMS_OF_DIABETIC_KETOACIDOSIS',
        A1C_BETWEEN_5_7_AND_5_9_AND_AGE_ABOVE_OR_EQUAL_60_AND_NEITHER_HAD_GESTATIONAL_DIABETES_NOR_BMI_EQUAL_OR_ABOVE_35 = 'A1C_BETWEEN_5_7_AND_5_9_AND_AGE_ABOVE_OR_EQUAL_60_AND_NEITHER_HAD_GESTATIONAL_DIABETES_NOR_BMI_EQUAL_OR_ABOVE_35',
        DIABETES_WITH_A1C_TOO_HIGH = 'DIABETES_WITH_A1C_TOO_HIGH',
        UNKNOWN_LAST_A1C_RESULT = 'UNKNOWN_LAST_A1C_RESULT',
        DOES_NOT_WANT_TO_REPLACE_SGLT2_DPP4_GLP1 = 'DOES_NOT_WANT_TO_REPLACE_SGLT2_DPP4_GLP1',
        NO_DIABETES_RISK = 'NO_DIABETES_RISK',
        BMI_TOO_LOW_FOR_WEIGHT_LOSS_PROGRAM = 'BMI_TOO_LOW_FOR_WEIGHT_LOSS_PROGRAM',
        BMI_TOO_LOW = 'BMI_TOO_LOW',
    }


}

